import {useEffect, useState} from "react";
import SiteService from "../services/SiteService";


export default function NyhetsBrevKNBF() {
	document.title = "Nyhetsbrev | KNBF"
	const [startedLoading, setStartedLoading] = useState(false);
	const [stats, setStats] = useState(
		[]
	);
	const [selectedIndex, setSelectedIndex] = useState(null);

	useEffect(() => {
		if (!stats || !selectedIndex) {}
			getStats();
	}, []);

	const getStats = async () => {
		setStartedLoading(true)
		// Perform the fetch request with the Authorization header
		const response = await fetch(`${SiteService.apiPath}/nyhetsbrev-stats`);

		if (!response.ok) {
			console.error('Failed to fetch stats:', response.status, response.statusText);
			return null;
		}

		const stats = await response.json();
		setStats(stats);
		stats.forEach(item => {
			item.sent_at = item.sent_at.substring(0, 10).split('-').reverse().join('-')
		})
		setSelectedIndex(0);
	};

	function NewsletterSingle({newsletter, i}){
		const [hover, setHover] = useState(false);

		return <li onClick={()=>setSelectedIndex(i)} style={{background:(selectedIndex===i||hover)?'#bfbfbf':'white',
			listStyle:'none', border:'1px solid #eee',padding:'10px',cursor:'pointer'
		}} 		onMouseEnter={() => setHover(true)}
			   onMouseLeave={() => setHover(false)}
		>
			<div>{newsletter.subject}</div>
			<div style={{fontSize:'0.8em',textAlign:'right'}}><i>{newsletter.sent_at.substring(0,10)}</i></div>
		</li>
	}

	return <div style={{fontFamily:'sans-serif',height:'100vh'}}>
		{!startedLoading && <button onClick={() => getStats(stats)}>Hent</button>}
		<div style={{
			width: '20%',
			float: 'left',
			overflowY: 'scroll',
			height: '100vh',
			background: '#00205b',
			color: 'white'
		}}>
			<h3 style={{textAlign: 'center'}}>Nyhetsbrev KNBF</h3>
			<ul style={{paddingLeft: '0', color: 'black', background: 'white'}}>
				{stats && stats.map((entry, i) => {
					return <NewsletterSingle newsletter={entry} i={i} key={i}/>
				})}</ul>
		</div>
		{selectedIndex !== null && <iframe src={stats[selectedIndex].mailbody}
		   style={{float: 'left',width: 'calc(80% - 5px)', height: '100%',minHeight:'500px'}}
		></iframe>}
	</div>
}

